import { useNavigate } from "react-router-dom";

function CMSHeader() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("t0k3nL06!nU5eR!?");
    navigate("/cms");
  };
  return (
    <div className="flex justify-between items-center  bg-white shadow-xl p-4 rounded-lg">
      <div className="flex items-center gap-24">
        <img
          src="/icons/mkahfi.png"
          width={100}
          height={100}
          alt="mkahfi"
          className="mx-auto grayscale brightness-0"
        />

        {/* <div className="flex justify-start items-center gap-6">
          <div className="cursor-pointer text-xl font-bold">Tamu</div>
          <div className="cursor-pointer text-xl font-bold">Ucapan</div>
        </div> */}
      </div>
      <button
        className="bg-pink-primary rounded-xl py-2 px-4 text-white w-32"
        onClick={handleLogout}
      >
        Keluar
      </button>
    </div>
  );
}

export default CMSHeader;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Auth from "./auth";
import CMSAdmin from "./cmsadmin";

function CMS() {
  const navigate = useNavigate();

  const isAuth = localStorage.getItem("t0k3nL06!nU5eR!?") !== null;

  return !isAuth ? <Auth /> : <CMSAdmin />;
}

export default CMS;

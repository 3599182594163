import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

/* eslint-disable jsx-a11y/alt-text */
const Fade = require("react-reveal/Fade");
function Gallery() {
  const [zoomedImage, setZoomedImage] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const galleryPics = [
    "/galleries/K16.jpg",
    "/galleries/K3.jpg",
    "/galleries/K26.jpg",
    "/galleries/K24.jpg",
    // "/images/galleries/K5.jpg",
    // "/images/galleries/K27.jpg",
    "/galleries/K15.jpg",
    "/galleries/K30.jpg",
  ];

  const onZoomImage = (image: string) => {
    if (openModal === false) {
      setZoomedImage(image);
      setOpenModal(true);
    }
  };

  return (
    <section className="py-16  container mx-auto max-w-7xl px-5 lg:px-0 relative bg-white">
      <Fade bottom>
        <h2 className="text-[40px] text-pink-secondary font-bold sn-font">
          Galeri Kami
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 px-0 mt-4 lg:px-16">
          {galleryPics?.map((image, index) => (
            <div
              className="bg-grey-primary w-full h-[250px] hover:shadow-xl hover:scale-200 cursor-pointer"
              key={image}
              onClick={() => onZoomImage(image)}
            >
              <img
                src={image}
                className={`object-cover h-[250px] w-full`}
                style={{ objectPosition: index === 2 ? "0 30%" : "" }}
              />
            </div>
          ))}
        </div>
      </Fade>
      {openModal && (
        <Transition appear show={!!openModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => setOpenModal(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="h-[90%] w-full max-w-2xl transform overflow-hidden text-center align-middle  transition-all">
                    <div className="md:w-[80%] rounded-2xl bg-white shadow-xl mx-auto">
                      <img
                        src={zoomedImage}
                        className="object-cover h-full w-full rounded-xl"
                      />
                    </div>
                    <div className="flex justify-center mt-6">
                      <img
                        onClick={() => setOpenModal(false)}
                        src="/icons/x-mark.svg"
                        style={{
                          filter: "invert(1)",
                          cursor: "pointer",
                        }}
                        width={25}
                      />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </section>
  );
}

export default Gallery;

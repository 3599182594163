import React, { useState, useRef } from "react";
import { toast } from "react-toastify";

interface AccordionItemProps {
  handleToggle: (id: number) => void;
  active: number | null;
  banks: Banks;
}

interface Banks {
  id: number;
  name: string;
  bankLogo: string;
  bankNo: string;
}

const AccordionItem = (props: AccordionItemProps) => {
  const contentEl: any = useRef();
  const { handleToggle, active, banks } = props;
  const { name, id, bankNo, bankLogo } = banks;

  return (
    <div className="rounded-xl bg-white px-4 py-4 lg:py-8 text-black text-left w-full lg:w-1/2">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle mb-3 ${
            active === id ? "active" : ""
          }`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="font-bold text-[20px]">{name}</h5>
          <img
            src="/icons/arrow.svg"
            alt="arrow"
            className={`transition-all ${
              active === id ? "rotate-0" : "rotate-180"
            }`}
          />
        </div>
        <div className="border-b border-grey-border w-full" />
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="flex justify-between items-center gap-2 mt-4">
          <div>
            <img
              src={bankLogo}
              className="mb-1 w-[60px] h-[20px]"
              width={60}
              height={20}
              alt="banklogo"
            />
            <p className="font-bold text-[20px]">{bankNo}</p>
            <p className="text-md">a/n {name}</p>
          </div>
          <button
            onClick={() => {
              navigator.clipboard.writeText(bankNo?.replaceAll(" ", ""));
              toast("No. rekening disalin", {
                position: "bottom-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "colored",
              });
            }}
            className="focus:outline-none bg-[#DFF3FE] py-2 px-3 text-[#2F7FEE] rounded-lg flex gap-2 items-center cursor-pointer w-32 md:w-auto flex-none"
          >
            <img src="/icons/copy.svg" width={20} alt="copy" />
            Salin no. rekening
          </button>
        </div>
      </div>
    </div>
  );
};

interface AccordionProps {
  banks: Banks;
}

const Accordion = ({ banks }: AccordionProps) => {
  const [active, setActive] = useState<number | null>(null);

  const handleToggle = (index: any) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  return (
    <>
      <AccordionItem
        active={active}
        handleToggle={handleToggle}
        banks={banks}
      />
    </>
  );
};

export default Accordion;

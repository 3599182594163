import React, { useRef } from "react";
import SurahDesktop from "./desktop";
import SurahMobile from "./mobile";

function Surah() {
  const ref = useRef(null);
  return (
    <>
      <div
        style={{
          position: "sticky",
          top: "5%",
          left: "0%",
          zIndex: "0",
        }}
        className="hidden md:block"
        ref={ref}
      >
        <SurahDesktop />
      </div>
      <div
        style={{
          position: "sticky",
          top: "5%",
          zIndex: "0",
        }}
        className="block md:hidden"
      >
        <SurahMobile />
      </div>
    </>
  );
}

export default Surah;

const Fade = require("react-reveal/Fade");
const Zoom = require("react-reveal/Zoom");

function BrideGroom() {
  return (
    <section className="container flex items-center m-auto max-w-7xl px-0 relative">
      <div className="flex justify-center items-center opacity-100">
        <div className="w-[25%] md:w-auto h-[500px]">
          <img
            src="/images/prewed1.png"
            className="h-[500px] object-cover"
            alt="prewed1"
          />
        </div>
        <div className="w-[50%] h-[500px]">
          <img
            src="/images/prewed2.jpg"
            className="h-[500px] object-cover"
            alt="prewed1"
          />
        </div>
        <div className="w-[25%] md:w-auto h-[500px]">
          <img
            src="/images/prewed3.jpg"
            className="h-[500px] object-cover"
            alt="prewed1"
          />
        </div>
      </div>
      <Zoom>
        <div
          className="absolute left-0 mx-0 mx-8 lg:mx-32"
          style={{ backdropFilter: "blur(3px)" }}
        >
          <div className="text-white bg-[rgba(0,0,0,0.6)] py-8 px-2 lg:px-32 rounded-xl">
            <h2 className="text-[45px] mb-8 md:text-[20px] lg:text-[60px] sn-font">
              <img
                src="/bismillah.png"
                alt="bismillah"
                className="invert w-[75%] md:w-[65%] mx-auto"
              />
            </h2>
            <p className="text-[18px] lg:text-[24px]">
              Assalamu`alaikum Warahmatullaahi Wabarakaatuh.
              <br />
              Dengan memohon ridho dan rahmat Allah SWT, kami bermaksud
              mengundang Bapak/Ibu/Saudara/i untuk menghadiri pernikahan kami:
            </p>
          </div>
        </div>
      </Zoom>
    </section>
  );
}

export default BrideGroom;

import Accordion from "../../helper/accordion";

const banks = [
  {
    id: 1,
    name: "Muhammad Kahfi",
    bankLogo: "/icons/bca.png",
    bankNo: "6820484205",
  },

  {
    id: 2,
    name: "Dianinoer Tamatalo Putri",
    bankLogo: "/icons/mandiri.png",
    bankNo: "070000 7544799",
  },
];

const Fade = require("react-reveal/Fade");
function Gift() {
  return (
    <section className="py-16 flex justify-center container mx-auto max-w-7xl px-5 lg:px-16 relative bg-grey-secondary text-white">
      <Fade bottom>
        <div className="bg-grey-third p-4 md:p-12 w-full rounded-3xl">
          <h2 className="text-[30px] mb-4 text-pink-secondary font-bold">
            Kado Pernikahan
          </h2>
          <p className="mb-8 mx-auto w-full lg:w-1/2 ">
            Doa restu Anda merupakan karunia yang sangat berarti bagi kami. Dan
            jika memberi adalah ungkapan tanda kasih Anda, Anda dapat memberi
            kado pernikahan secara cashless.
          </p>
          <div className="flex flex-col lg:flex-row gap-4 justify-between items-start">
            <Accordion banks={banks[0]} />
            <Accordion banks={banks[1]} />
          </div>
          <p className="mt-6 lg:mb-4 text-[20px] font-bold text-pink-secondary">
            Alamat kirim kado
          </p>
          <p className="w-full lg:w-1/2 mx-auto">
            Jl. Singosari Raya No.32, Kel. Mekar Jaya, Kec. Sukmajaya, Kota
            Depok, Jawa Barat 16411
          </p>
        </div>
      </Fade>
    </section>
  );
}

export default Gift;

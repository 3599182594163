function Bgm({ playing, setPlaying }: any) {
  const audio: any = document.getElementById("player") ?? "";

  const togglePause = () => {
    if (playing === true) {
      setPlaying(false);
      audio?.pause();
    } else {
      setPlaying(true);
      audio?.play();
    }
  };

  return (
    <>
      <iframe
        src="/bgm/silence.mp3"
        allow="autoplay"
        id="audio"
        title="silence-audio"
        style={{ display: "none" }}
      ></iframe>

      <audio id="player" loop autoPlay>
        <source src="/bgm/cover.mp3" type="audio/mp3" />
      </audio>
      <div className="fixed left-4 bottom-10">
        <div
          onClick={togglePause}
          className="cursor-pointer bg-[#D883AA] rounded-full p-4 hover:opacity-75"
        >
          <img
            src={playing ? "/icons/pause.png" : "/icons/play.png"}
            width={20}
            height={20}
            alt="control"
          />
        </div>
      </div>
    </>
  );
}

export default Bgm;

import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

const Fade = require("react-reveal/Fade");
function DateAndPlace() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const opacity = useTransform(scrollYProgress, [0, 0.45], ["0%", "100%"]);

  return (
    <div
      ref={ref}
      className="w-full h-auto overflow-hidden relative grid place-items-center"
    >
      <motion.div
        style={{ opacity: opacity }}
        className="container mx-auto max-w-7xl px-5 lg:px-16 relative bg-black"
      >
        <div className="flex flex-col lg:flex-row items-center justify-between mx-auto w-full">
          <Fade bottom>
            <div className="">
              <div className="w-full lg:mx-auto py-8 my-8 px-8 lg:my-16 lg:px-8 lg:py-16 border border-pink-third rounded-lg  max-h-[800px] relative bg-white">
                <h2 className="text-black text-[40px] text-pink-secondary font-bold">
                  Resepsi
                </h2>
                <h3 className="mt-4 font-bold text-black text-[18px]">
                  Sabtu, 16 Desember 2023
                </h3>
                <h3 className="font-bold text-black text-[18px]">
                  Pukul 11.00 - 13:00 WIB
                </h3>
                <h4 className="mt-2 text-[18px]">Yang diselenggarakan di</h4>
                <h4 className="mt-6 font-bold text-[20px]">
                  Gedung Sabha Widya
                </h4>
                <p className="mt-4 text-[18px] w-full lg:max-w-[60%] mx-auto">
                  Gedung Sabha Widya (Sebelah Wisma Makara UI), Jl. Prof. DR.
                  Miriam Budiardjo, RT.5/RW.12, Srengseng Sawah, Kec. Jagakarsa,
                  Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12630
                </p>
                <a href="/lokasi" target="_blank">
                  <button className="p-6 bg-pink-secondary rounded-full flex items-center gap-4 mx-auto mt-6 text-white w-[80%] md:w-72">
                    <span className="mx-auto font-bold">Cek Penunjuk Arah</span>
                  </button>
                </a>
              </div>{" "}
            </div>
          </Fade>
        </div>
      </motion.div>
    </div>
  );
}

export default DateAndPlace;

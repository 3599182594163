const Fade = require("react-reveal/Fade");
function PoweredBy() {
  return (
    <section className="py-16 container mx-auto max-w-7xl px-5 lg:px-0 relative bg-white text-black">
      <Fade bottom>
        <div className="mx-auto">
          <p className="font-bold mb-2">Web, Photograph & Design.</p>
          <p>Powered by</p>
          <a href="https://mkahfi.id" target="_blank" rel="noreferrer">
            <img
              src="/icons/logo.png"
              alt="credit"
              className="mx-auto mt-4"
              width={150}
            />
            <div className="flex items-center justify-center gap-1 mx-auto">
              <img src="/icons/web.svg" width={15} alt="web" />
              <p>mkahfi.id</p>
            </div>
          </a>
        </div>
      </Fade>
    </section>
  );
}

export default PoweredBy;

function Video() {
  return (
    <section className="container mx-auto max-w-full px-0 relative">
      <div className="flex justify-center items-center opacity-50">
        <video
          id="video-player"
          className="w-full"
          src="/videos/vid.mp4"
          autoPlay
          loop
          muted
        />
      </div>
    </section>
  );
}

export default Video;

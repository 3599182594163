import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CMS from "./components/cms";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Edit from "./components/cms/cmsadmin/edit";
import Add from "./components/cms/cmsadmin/add";
import Location from "./pages/location";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/:uri" element={<App />} />
        <Route path="cms" element={<CMS />}></Route>
        <Route path="/cms/add" element={<Add />} />
        <Route path="/cms/:uri/edit" element={<Edit />} />
        <Route path="/lokasi" element={<Location />} />
      </Routes>
    </BrowserRouter>
    <ToastContainer />
  </>
);

reportWebVitals();

import { motion, useScroll, useTransform } from "framer-motion";
import React, { useRef } from "react";
// const Fade = require("react-reveal/Fade");
const Zoom = require("react-reveal/Zoom");

function SurahMobile() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  const { scrollYProgress: scrollYProgressContent } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const textY = useTransform(scrollYProgress, [0, 2], ["0%", "100%"]);
  const putriMove = useTransform(
    scrollYProgressContent,
    [0, 0.5],
    ["-20rem", "-5rem"]
  );
  const kahfiMove = useTransform(
    scrollYProgressContent,
    [0, 0.5],
    ["-20rem", "-5rem"]
  );
  const opacity = useTransform(
    scrollYProgressContent,
    [0.25, 0.5],
    ["0%", "100%"]
  );

  return (
    <div
      ref={ref}
      className="w-full h-screen overflow-hidden relative grid place-items-center"
    >
      <motion.h1
        style={{ y: textY }}
        className="font-bold text-white text-7xl md:text-9xl relative z-10 h-screen"
      >
        <section className="container h-[1080px]  flex items-center m-auto max-w-7xl px-0 relative">
          <img src="/bgsurah.png" alt="" className="w-full h-full md:h-auto" />
          <div className="absolute bottom-[15rem] w-full flex justify-between items-end">
            <motion.img
              src="/images/putri-arum.png"
              className="w-[70%] md:w-[20%] -ml-[10rem]"
              alt=""
              style={{ marginLeft: putriMove }}
            />
            <motion.img
              src="/images/kahfi-arum.png"
              className="w-[70%] md:w-[20%] -mr-[10rem]"
              alt=""
              style={{ marginRight: kahfiMove }}
            />
          </div>
          <Zoom>
            <div
              className="absolute top-[12%] -translate-x-1/2 -translate-y-1/2 left-0 mx-0 mx-8 lg:mx-32"
              style={{ backdropFilter: " blur(3px)" }}
            >
              <motion.div
                style={{ opacity: opacity }}
                className="text-white bg-[rgba(0,0,0,0.2)] lg:px-32 py-8 rounded-xl"
              >
                <p className="text-[18px] leading-7 md:leading-10 md:text-[15px] lg:text-[24px]">
                  Di antara tanda-tanda (kebesaran)-Nya ialah bahwa Dia
                  menciptakan pasangan-pasangan untukmu dari (jenis) dirimu
                  sendiri agar kamu merasa tenteram kepadanya. Dia menjadikan di
                  antaramu rasa cinta dan kasih sayang. Sesungguhnya pada yang
                  demikian itu benar-benar terdapat tanda-tanda (kebesaran
                  Allah) bagi kaum yang berpikir.
                </p>
                <h2 className="text-[20px] lg:text-[50px] mt-4">
                  Ar. Rum (30) : 21
                </h2>
              </motion.div>
            </div>
          </Zoom>
        </section>
      </motion.h1>
    </div>
  );
}

export default SurahMobile;

import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface IOlogin {
  username: string;
  password: string;
}

function login({ username, password }: IOlogin) {
  const rand = () => Math.random().toString(36).substr(2);
  const token = (length: number) => {
    return (rand() + rand() + rand() + rand()).substr(0, length);
  };

  localStorage.setItem("t0k3nL06!nU5eR!?", token(32));
}

function Auth() {
  const [input, setInput] = React.useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();

  const onChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setInput({ ...input, [id]: e.target.value });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (input?.username === "admin" && input?.password === "bismillah") {
      await login({
        username: input?.username,
        password: input?.password,
      });
      navigate("/cms");
    } else {
      toast.error("Gagal masuk", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div className=" flex-row justify-center max-w-full m-auto px-6 md:px-20 pt-44 h-screen overflow-hidden bg-gradient-to-r from-black to-pink-secondary">
      <div className="max-w-2xl mx-auto">
        <h2 className="text-4xl font-bold text-white">
          <img
            src="/icons/mkahfi.png"
            width={150}
            height={150}
            alt="mkahfi"
            className="mx-auto grayscale brightness-200"
          />
        </h2>
        <form
          onSubmit={onSubmit}
          className="my-8 p-8 bg-white shadow-xl rounded-xl text-left flex justify-center"
        >
          <div className="w-3/4 mx-auto">
            <label htmlFor="username">Username</label>
            <input
              id="username"
              type="text"
              name="username"
              className="border border-pink-primary focus:outline-pink-primary block my-4 rounded-lg p-2 w-full"
              value={input?.username}
              onChange={(e) => onChangeInput(e, "username")}
            />
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              name="password"
              className="border border-pink-primary focus:outline-pink-primary block my-4 rounded-lg p-2 w-full"
              value={input?.password}
              onChange={(e) => onChangeInput(e, "password")}
            />
            <button
              type="submit"
              className="bg-pink-secondary p-4 rounded-lg w-full text-white"
            >
              Masuk
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Auth;

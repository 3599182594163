import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { url } from "../../../../constants";
import CMSHeader from "../../header";

const baseURL = "https://api-putri.mkahfi.id";
function Edit() {
  const { uri } = useParams();
  const [input, setInput] = useState({
    name: "",
    partner: "",
    photo: "",
    prefix: "",
  });
  const navigate = useNavigate();
  const onChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setInput({ ...input, [id]: e.target.value });
  };

  useEffect(() => {
    getGuestById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGuestById = async () => {
    await axios.get(`${url}guests/${uri}`).then((res) => {
      setInput(res?.data?.data);
    });
  };

  const onChangeFile = async (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    const imageData = new FormData();
    imageData.append("photo", file);
    const res = await axios.post(`${url}upload/image`, imageData);
    setInput({
      ...input,
      photo: `${baseURL}${res?.data?.data?.url}`,
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const payload = {
      name: input?.name,
      photo: input?.photo,
      partner: input?.partner,
      prefix: input?.prefix,
    };
    await axios
      .put(`${url}guests/${uri}`, payload)
      .then((res) => {
        toast.success("Sukses mengubah data", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/cms");
      })
      .catch((err) => {
        const reason = err?.message ?? "Gagal mengubah data";
        toast.error(reason, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handleBack = () => {
    navigate("/cms");
  };

  return (
    <div className="text-left max-w-full m-auto px-6 md:px-20 py-20 min-h-screen bg-[#e9e9e9]">
      <div className="max-w-7xl mx-auto">
        <CMSHeader />
        <div className="mt-12">
          <button
            onClick={handleBack}
            className="bg-pink-primary py-2 px-4 text-white rounded-lg my-4"
          >
            Kembali ke daftar tamu
          </button>
          <form onSubmit={onSubmit} className="bg-white p-4 rounded-lg">
            <div className="my-4">
              <label htmlFor="name">Nama Tamu</label>
              <input
                id="name"
                type="text"
                name="name"
                className="border border-pink-primary focus:outline-pink-primary block my-4 rounded-lg p-2 w-full"
                onChange={(e) => onChangeInput(e, "name")}
                value={input?.name}
              />
            </div>
            <div className="my-4">
              <label htmlFor="partner">Nama Partner</label>
              <input
                id="partner"
                type="text"
                name="partner"
                className="border border-pink-primary focus:outline-pink-primary block my-4 rounded-lg p-2 w-full"
                onChange={(e) => onChangeInput(e, "partner")}
                value={input?.partner}
              />
            </div>
            <div className="my-4">
              <label htmlFor="prefix">Prefix</label>
              <input
                id="prefix"
                type="text"
                name="prefix"
                className="border border-pink-primary focus:outline-pink-primary block my-4 rounded-lg p-2 w-full"
                value={input?.prefix}
                onChange={(e) => onChangeInput(e, "prefix")}
              />
            </div>
            <div className="my-4">
              <label>Foto Tamu</label>
              <div className="flex items-center gap-4">
                <div>
                  <label htmlFor="uploadFile">
                    <div className="bg-grey-primary w-32 h-32 border border-grey-primary my-4 cursor-pointer rounded-lg p-2 flex items-center justify-center">
                      <div className="text-7xl m-auto text-white">+</div>
                    </div>
                  </label>
                  <input
                    type="file"
                    onChange={onChangeFile}
                    className="hidden"
                    id="uploadFile"
                  />
                </div>
                {input?.photo ? (
                  <img
                    src={input?.photo}
                    className="w-32 h-32 object-cover"
                    alt="guest_photo"
                  />
                ) : null}
              </div>
            </div>
            <button
              type="submit"
              className="bg-pink-secondary p-4 rounded-lg w-full text-white"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Edit;

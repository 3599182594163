import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { url } from "../../../constants";
import CMSHeader from "../header";

export interface GuestData {
  name: string;
  partner: string | null;
  photo: string;
  uri?: string;
  id?: number;
  prefix: string;
  created_at?: string;
  updated_at?: string;
}

interface Guest {
  data: GuestData[];
  links: any;
  meta: any;
}

function CMSAdmin() {
  const [guestList, setGuestList] = useState<Guest>();
  const [keyword, setKeyword] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageSize, _setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchTerm(keyword);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [keyword]);

  const getList = async () => {
    await axios(`guests`, {
      baseURL: url,
      method: "GET",
      params: {
        pageSize: pageSize,
        page: page,
      },
    })
      .then((res) => {
        setGuestList(res?.data);
      })
      .catch((err) => {
        const reason = err?.message ?? "Gagal memuat data";
        toast.error(reason, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handleAdd = () => {
    navigate("add");
  };

  const handleEdit = (uri: string) => {
    navigate(`${uri}/edit`);
  };

  const onSearch = (e: any) => {
    setKeyword(e.target.value);
  };

  const handleDelete = async (uri: string) => {
    await axios.delete(`${url}guests/${uri}`).then(() => {
      toast.success("Sukses menghapus data", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
    await getList();
  };

  return (
    <div className="text-left max-w-full m-auto px-6 md:px-20 min-h-screen overflow-hidden pt-20 bg-[#e9e9e9]">
      <div className="max-w-7xl mx-auto">
        <CMSHeader />
        <div className="my-12">
          <div className="rounded-lg shadow-md border p-4 bg-white">
            <div className="flex flex-col md:flex-row gap-4 justify-between items-start md:items-center">
              <button
                onClick={handleAdd}
                className="bg-pink-primary py-2 px-4 text-white rounded-lg"
              >
                Tambah Tamu
              </button>
              <input
                className="p-2 rounded-lg w-full md:w-[60%] lg:w-[35%] border border-pink-primary focus:outline-pink-primary"
                placeholder="Cara nama tamu di halaman ini..."
                onChange={onSearch}
              />
            </div>
            <div className="mt-8 overflow-auto max-h-[35rem]">
              <table className="table-auto overflow-auto mt-4 w-full border-collapse border-spacing-0">
                <tr>
                  <th className="p-2 rounded-l-xl">No</th>
                  <th className="p-2">Nama tamu</th>
                  <th className="p-2">Partner</th>
                  <th className="p-2">Prefix</th>
                  <th className="p-2">URI</th>
                  <th className="p-2 rounded-r-xl">Foto tamu</th>
                  <th className="p-2">Action</th>
                </tr>
                {guestList?.data?.length === 0 ? (
                  <tr>
                    <td colSpan={6}>
                      <div className="flex justify-center h-36 items-center w-full">
                        Tamu belum ada, silakan masukkan terlebih dahulu
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {guestList?.data?.filter((res) =>
                      res?.name
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ).length === 0 ? (
                      <tr>
                        <td colSpan={6}>
                          <div className="flex justify-center h-36 items-center w-full">
                            Tamu yang kamu cari tidak ada.
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {guestList?.data
                          ?.filter((res) =>
                            res?.name
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          ?.map((val, index) => {
                            const indexVal =
                              page === 1
                                ? index - (page - 1) * pageSize + 1
                                : index + (page - 1) * pageSize + 1;

                            return (
                              <tr>
                                <td className="p-2">{indexVal}</td>
                                <td className="p-2">{val?.name}</td>
                                <td className="p-2">{val?.partner}</td>
                                <td className="p-2">{val?.prefix ?? "-"}</td>
                                <td className="p-2">{val?.uri}</td>
                                <td className="p-2">
                                  <img
                                    src={val?.photo ?? "/icons/fallback.jpg"}
                                    alt="guest_pic"
                                    className="w-[80px] h-[100px] object-cover"
                                  />
                                </td>
                                <td className="p-2">
                                  <div className="flex items-center gap-3 justify-start">
                                    <button
                                      onClick={() => handleEdit(val?.uri ?? "")}
                                      className="rounded-lg px-2 p-1 bg-white border border-grey-primary text-black w-16"
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleDelete(val?.uri ?? "")
                                      }
                                      className="rounded-lg px-2 p-1 bg-pink-primary border border-grey-primary text-white w-16"
                                    >
                                      Hapus
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </>
                    )}
                  </>
                )}
              </table>
            </div>
            <div className="flex justify-center items-center gap-2 mt-16 justify-start px-4 overflow-auto no-scrollbar flex-nowrap max-w-xl mx-auto">
              <div
                onClick={() => {
                  if (page !== 1) {
                    setPage(page - 1);
                  }
                }}
                className={` flex-none w-8 h-8 text-white flex justify-center items-center ${
                  page === 1
                    ? "bg-grey-primary cursor-not-allowed"
                    : "bg-pink-third cursor-pointer"
                } `}
              >
                {"<"}
              </div>
              <div className="max-w-[25rem] overflow-auto flex items-center gap-2">
                {Array.from({ length: guestList?.meta?.last_page }).map(
                  (res, index) => {
                    return (
                      <div
                        onClick={() => setPage(index + 1)}
                        className={`cursor-pointer flex-none w-8 h-8 text-white flex justify-center items-center ${
                          page === index + 1 ? "bg-[#000]" : "bg-pink-third"
                        }`}
                      >
                        {index + 1}
                      </div>
                    );
                  }
                )}
              </div>
              <div
                onClick={() => {
                  if (page !== guestList?.meta?.last_page) {
                    setPage(page + 1);
                  }
                }}
                className={` flex-none  w-8 h-8 text-white flex justify-center items-center ${
                  page === guestList?.meta?.last_page
                    ? "bg-grey-primary cursor-not-allowed"
                    : "bg-pink-third cursor-pointer"
                } `}
              >
                {">"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CMSAdmin;

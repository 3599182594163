import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { url } from "../../constants";
// import { GuestData } from "../cms/cmsadmin";

const Fade = require("react-reveal/Fade");

interface IOAttendance {
  guestId?: number;
  name?: string;
  prefix?: string;
  partner?: string | null;
}

// interface CommentList {
//   content: string;
//   created_at: string;
//   guest: GuestData;
//   guest_id: number;
//   id: number;
//   update_at: string;
// }

function Attendance({ guestId, name, prefix, partner }: IOAttendance) {
  const [message, setMessage] = useState("");
  const [commentList, setCommentList] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [isReady, setIsReady] = useState(false);
  const commentRef = useRef(document.createElement("div"));
  const onChangeMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setMessage(value);
  };

  useEffect(() => {
    if (isReady === false) {
      setTimeout(() => {
        setIsReady(true);
      }, 1500);
    }
  }, [isReady]);

  useEffect(() => {
    getComments(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    await axios
      .post(`${url}comments`, {
        guest_id: guestId,
        content: message,
      })
      .then(async (res) => {
        setPage(1);
        await setMessage("");
        await axios
          .get(`${url}comments`, {
            params: {
              page: 1,
              // limit: 1000,
            },
          })
          .then((res) => {
            setCommentList(res?.data?.data);
            setLastPage(res?.data?.meta?.last_page);
          });
        commentRef?.current.scrollTo({ top: 0, behavior: "smooth" });
      });
  };

  const getComments = async (page?: number) => {
    await axios
      .get(`${url}comments`, {
        params: {
          page: page,
          // limit: 1000,
        },
      })
      .then((res) => {
        setCommentList([...commentList, ...res?.data?.data]);
        setLastPage(res?.data?.meta?.last_page);
      });
  };

  const onScroll = async (e: any) => {
    const bottom =
      // e.target.scrollTop === e.target.scrollHeight - e.target.offsetHeight;
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1;
    // e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop < 1;
    // e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight ===
    //   0 ||
    // e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight === 11;

    if (bottom && page < lastPage && isReady === true) {
      setPage(page + 1);
      setIsReady(false);
      await axios
        .get(`${url}comments`, {
          params: {
            page: page + 1,
          },
        })
        .then((res) => {
          setCommentList([...commentList, ...res?.data?.data]);
          setLastPage(res?.data?.meta?.last_page);
        });
    }
  };

  return (
    <section className="py-8 container mx-auto max-w-7xl px-5 lg:px-0 relative bg-grey-third">
      <Fade bottom>
        <div className="my-6 text-white max-w-full lg:max-w-[75%] flex justify-center mx-auto">
          <div className="w-full">
            <h1 className="text-[30px] font-bold sf-font text-pink-secondary">
              Tinggalkan Pesanmu
            </h1>
            <div className="mt-4">
              <div
                className={`${
                  commentList?.length ? "" : "flex"
                } rounded-lg w-full h-[270px] overflow-auto mt-4 bg-white p-4`}
                id="container-comment"
                onScroll={onScroll}
                ref={commentRef}
              >
                {commentList?.length ? (
                  commentList?.map((val: any) => (
                    <div className="flex items-center gap-4 mb-3 transition-all">
                      <img
                        src={val?.guest?.photo ?? "/icons/fallback.jpg"}
                        className="flex-none rounded-full w-[50px] h-[50px] object-cover"
                        width={50}
                        height={50}
                        alt="fallback"
                      />
                      <div className="block w-full">
                        <h3 className="text-pink-primary font-bold text-left sf-font">
                          {val?.guest?.prefix} {val?.guest?.name}{" "}
                          {val?.guest?.partner !== null
                            ? `& ${val?.guest?.partner}`
                            : ""}{" "}
                        </h3>
                        <p className="text-black pb-1 text-left">
                          {val?.content}
                        </p>
                        <div className="border-b border-[#828282] w-full" />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-black m-auto">
                    Belum ada ucapan ke mempelai, jadilah yang pertama memberi
                    ucapan...
                  </div>
                )}
                {isReady === false && (
                  <div className="text-black mt-2">
                    Memuat pesan lebih banyak...
                  </div>
                )}
              </div>
              <p className="mt-6 mb-2 text-left font-bold sf-font">
                Nama Undangan : {prefix} {name}{" "}
                {partner !== null ? `& ${partner}` : ""}
              </p>
              <textarea
                className="text-black rounded-lg p-4 w-full h-[200px]"
                placeholder="Tulis pesanmu untuk mempelai pengantin..."
                onChange={onChangeMessage}
                value={message}
              ></textarea>
              <button
                onClick={onSubmit}
                className={`mt-4 p-4 text-center rounded-lg w-full focus:outline-none sf-font ${
                  message === ""
                    ? "cursor-not-allowed bg-grey-primary"
                    : "cursor-pointer bg-pink-secondary"
                }`}
                disabled={message === ""}
              >
                Tinggalkan Pesan
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
}

export default Attendance;

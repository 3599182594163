import { useEffect, useState } from "react";
import { useCountdown } from "../../helper/countDown";

const Fade = require("react-reveal/Fade");

type CountdownProps = {
  isNotAuth: boolean;
};

function CountDown({ isNotAuth }: CountdownProps) {
  const theWeddingDateTime = "2023-12-16 11:00";
  const [days, hours, minutes, seconds] = useCountdown(theWeddingDateTime);
  const [status, setStatus] = useState("");
  // const hasStarted = days < 0 && hours < 0 && minutes < 0 && seconds < 0;
  const currentTime = new Date().getTime();
  const startTime = new Date("2023-12-16 11:00").getTime();
  const endTime = new Date("2023-12-16 13:00").getTime();

  useEffect(() => {
    if (currentTime >= startTime && currentTime <= endTime) {
      // your code goes here
      setStatus("has-started");
    }
    if (currentTime <= startTime) {
      setStatus("has-not-started");
    }
    if (currentTime >= endTime) {
      setStatus("has-done");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  return (
    <Fade bottom>
      <section
        id="countdown"
        className="container mx-auto max-w-7xl md:px-0 relative"
      >
        <div className="py-24 flex justify-center bg-grey-third">
          {isNotAuth ? (
            <div className="text-white text-md md:text-4xl md:w-3/4 mx-auto md:leading-[3rem]">
              Mohon maaf, link halaman undangan salah atau tidak terdaftar.
              Silahkan cek kembali atau hubungi mempelai untuk mendapatkan link
              halaman undangan yang benar.
            </div>
          ) : (
            <div className="text-white">
              {/* <img src="/icons/line.svg" alt="line" className="mx-auto" /> */}
              <h3 className="text-4xl mx-auto sf-font text-pink-secondary">
                Hitung Mundur
              </h3>
              <div className="flex items-center justify-center gap-12 md:gap-24 mt-8 text-[2rem]">
                <div>
                  <p className="text-[30px] lg:text-[60px] font-bold sf-font">
                    {days > 0 ? days : 0}
                    {/* {days} */}
                  </p>
                  <p className="text-[14px] lg:text-[20px] sf-font">Hari</p>
                </div>
                <div>
                  <p className="text-[30px] lg:text-[60px] font-bold sf-font">
                    {hours > 0 ? hours : 0}
                    {/* {hours} */}
                  </p>
                  <p className="text-[14px] lg:text-[20px] sf-font">Jam</p>
                </div>
                <div>
                  <p className="text-[30px] lg:text-[60px] font-bold sf-font">
                    {minutes > 0 ? minutes : 0}
                    {/* {minutes} */}
                  </p>
                  <p className="text-[14px] lg:text-[20px] sf-font">Menit</p>
                </div>
                <div>
                  <p className="text-[30px] lg:text-[60px] font-bold sf-font">
                    {seconds > 0 ? seconds : 0}
                    {/* {seconds} */}
                  </p>
                  <p className="text-[14px] lg:text-[20px] sf-font">Detik</p>
                </div>
              </div>
              {status === "has-started" && (
                <div className="mt-8 flex items-center justify-center">
                  <p className="font-bold text-[20px] flex justify-center">
                    Acara telah dimulai
                  </p>
                </div>
              )}
              {status === "has-done" && (
                <div className="mt-8 flex items-center justify-center">
                  <p className="font-bold text-[20px] flex justify-center">
                    Acara telah selesai
                  </p>
                </div>
              )}
              {/* <div className="mt-8 flex items-center justify-center">
            <div>
              <p className="font-bold text-[20px]">Notes</p>
              <p className="mt-4 w-[100%] lg:w-[50%] mx-auto text-[20px]">
                Dalam upaya mengurangi penyebaran Covid 19 pada masa pandemi,
                kami harapkan kedatangan para tamu undangan agar menjalankan
                protokol yang berlaku.
              </p>
            </div>
          </div> */}
            </div>
          )}
        </div>
      </section>
    </Fade>
  );
}

export default CountDown;

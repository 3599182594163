import React, { useRef } from "react";
const Fade = require("react-reveal/Fade");
function Bride() {
  const ref = useRef(null);
  return (
    <div
      style={{
        position: "sticky",
        top: "3%",
        zIndex: "0",
      }}
      className="w-full bg-black overflow-hidden grid place-items-center "
    >
      <div ref={ref} className="w-full overflow-hidden h-screen relative">
        <section className="container mx-auto max-w-7xl px-5 md:px-0 bg-grey-third py-4 md:py-16">
          <div className="flex flex-col lg:flex-row items-center justify-between mx-auto w-full md:w-[60%] lg:w-[75%] gap-2 lg:gap-8">
            <Fade left>
              <div className="mx-auto py-6 lg:py-8 w-full lg:w-1/2 rounded-[100px] bg-black px-8">
                <img
                  src="/bride/kahfi.png"
                  className="rounded-full object-cover w-[125px] h-[125px] lg:w-[250px] lg:h-[250px] mx-auto mb-6"
                  alt="bride1"
                />
                <h3 className="font-bold text-pink-secondary text-[30px]">
                  Muhammad Kahfi
                </h3>
                <p className="text-white mt-2">Putra Keempat dari Pasangan</p>
                <p className="mt-2 font-bold text-white">
                  H Mohamad Djuhdi (Alm.), Hj Etty Herawati
                </p>
              </div>{" "}
            </Fade>
            <Fade right>
              <div className="mx-auto py-6 lg:py-8 w-full lg:w-1/2 rounded-[100px] bg-black px-8">
                <img
                  src="/bride/putri.png"
                  className="rounded-full object-cover w-[125px] h-[125px] lg:w-[250px] lg:h-[250px] mx-auto mb-6"
                  alt="bride1"
                />
                <h3 className="font-bold text-pink-secondary text-[30px]">
                  Dianinoer Tamatalo Putri
                </h3>
                <p className="text-white mt-2">Putri Pertama dari Pasangan</p>
                <p className="mt-2 font-bold text-white">
                  Rusuhan Tamatalo, Adianiwati
                </p>
              </div>
            </Fade>
          </div>
          {/* <motion.div
            //
            style={{ opacity: opacity }}
            className="absolute top-0 left-1/2 transform -translate-x-1/2 w-full"
          >
            <DateAndPlace />
          </motion.div> */}
        </section>
      </div>
    </div>
  );
}

export default Bride;

import Plx from "react-plx";
import { ParallaxProps } from "../types";
import { Link } from "react-scroll";

function MobileParallax({
  name,
  partner,
  photo,
  onOpenInvitation,
  isLocationPage,
  prefix,
  setIsKahfiLoaded,
  setIsPutriLoaded,
}: ParallaxProps) {
  return (
    <Plx
      parallaxData={[
        {
          start: 0,
          end: 2500,
          // easing: "ease-in",
          properties: [
            {
              startValue: 1,
              endValue: 0,
              property: "opacity",
            },
          ],
        },
      ]}
    >
      <div className="h-[450vh] bg-pink-primary relative overflow-hidden">
        <Plx
          parallaxData={[
            {
              start: 0,
              end: 12000,
              // easing: "ease-in",
              properties: [
                {
                  startValue: 1,
                  endValue: 20.0,
                  property: "scale",
                },
                {
                  startValue: 1,
                  endValue: 0,
                  property: "opacity",
                },
                {
                  startValue: 0,
                  endValue: 2500,
                  property: "translateY",
                },
                {
                  startValue: 0,
                  endValue: 300,
                  property: "translateX",
                },
              ],
            },
          ]}
          style={{
            position: "fixed",
            right: "0%",
            top: "10%",
            width: "100%",
            zIndex: 100,
            bottom: "0%",
          }}
        >
          <img
            // style={{ width: "35%", marginLeft: "40%", marginTop: "10%" }}
            src="/kahfi1.png"
            alt="foreground"
            className="absolute h-[85%] bottom-0 left-[40%] smd:left-[45%]"
            onLoad={() => setIsKahfiLoaded(true)}
          />
        </Plx>
        <Plx
          parallaxData={[
            {
              start: 0,
              end: 2500,
              // easing: "ease-in",
              properties: [
                {
                  startValue: 1,
                  endValue: 20.0,
                  property: "scale",
                },
                {
                  startValue: 1,
                  endValue: 0,
                  property: "opacity",
                },
                {
                  startValue: 0,
                  endValue: 2000,
                  property: "translateY",
                },
                {
                  startValue: 0,
                  endValue: -200,
                  property: "translateX",
                },
              ],
            },
          ]}
          style={{
            position: "fixed",
            right: "0%",
            top: 0,
            width: "100%",
            zIndex: 100,
            bottom: "0%",
          }}
        >
          <img
            src="/putri1.png"
            alt="foreground"
            className="absolute h-[70%] bottom-0 right-[40%] smd:right-[45%]"
            onLoad={() => setIsPutriLoaded(true)}
          />
        </Plx>

        <Plx
          parallaxData={[
            {
              start: 0,
              end: 500,
              properties: [
                {
                  startValue: 1,
                  endValue: 0,
                  property: "opacity",
                },
              ],
            },
          ]}
          style={{
            position: "fixed",
            left: 0,
            top: "26vw",
            width: "100%",
            zIndex: "999",
          }}
        >
          <div className="fixed text-white w-full left-1/2 transform -translate-x-1/2 bottom-10  text scale-100">
            <p className="text-xl">
              {" "}
              {!isLocationPage ? "Undangan Pernikahan" : "Lokasi Pernikahan"}
            </p>
            <h2 className="text-[40px] sf-font font-bold mt-1">
              Kahfi <span className="text-[40px]">{"&"}</span> Putri
            </h2>
            <div className="mt-4">
              {!isLocationPage && (
                <>
                  <p>Dear</p>
                  <p className="mt-2">
                    {prefix} {name} {partner !== null ? `& ${partner}` : ""}
                  </p>
                  <img
                    src={photo !== null ? photo : `/icons/fallback.jpg`}
                    className="rounded-full w-[100px] h-[100px] object-cover mt-2 mx-auto"
                    width={100}
                    height={100}
                    alt="guest-pic"
                  />
                </>
              )}
              <Link
                to="main"
                spy={true}
                smooth={true}
                offset={0}
                duration={4000}
              >
                <button
                  onClick={onOpenInvitation}
                  className="p-4 bg-pink-secondary rounded-full flex items-center gap-4 mx-auto mt-8 focus:outline-none"
                >
                  {isLocationPage ? null : (
                    <img src="/icons/mail.svg" alt="mail-icon" />
                  )}
                  <span>
                    {!isLocationPage ? "Buka Undangan" : "Penunjuk Arah"}
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </Plx>
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            zIndex: 200,
            paddingTop: "56%",
            height: "0vh",
            width: "100%",
          }}
        >
          <div
            style={{
              background: "#000",
              height: "100%",
            }}
          ></div>
        </div>
      </div>
    </Plx>
  );
}

export default MobileParallax;

import DesktopParallax from "./desktop";
import MobileParallax from "./mobile";
import TabletParallax from "./tablet";
import { ParallaxProps } from "./types";

export default function Intro({
  name,
  partner,
  photo,
  onOpenInvitation,
  isLocationPage,
  id,
  ref,
  prefix,
  setIsKahfiLoaded,
  setIsPutriLoaded,
}: ParallaxProps) {
  return (
    <div ref={ref}>
      <div className="hidden md:hidden lg:block">
        <DesktopParallax
          onOpenInvitation={onOpenInvitation}
          name={name}
          partner={partner}
          photo={photo}
          prefix={prefix}
          isLocationPage={isLocationPage}
          setIsKahfiLoaded={setIsKahfiLoaded}
          setIsPutriLoaded={setIsPutriLoaded}
        />
      </div>
      <div className="block lg:hidden" id={id}>
        <MobileParallax
          onOpenInvitation={onOpenInvitation}
          name={name}
          partner={partner}
          photo={photo}
          prefix={prefix}
          setIsKahfiLoaded={setIsKahfiLoaded}
          setIsPutriLoaded={setIsPutriLoaded}
          isLocationPage={isLocationPage}
        />
      </div>
      {/* <div className="hidden md:block lg:hidden">
        <TabletParallax
          onOpenInvitation={onOpenInvitation}
          name={name}
          partner={partner}
          photo={photo}
          prefix={prefix}
          isLocationPage={isLocationPage}
          setIsKahfiLoaded={setIsKahfiLoaded}
          setIsPutriLoaded={setIsPutriLoaded}
        />
      </div> */}
    </div>
  );
}

import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./App.css";
import Attendance from "./components/attendance";
// import Bgm from "./components/bgm";
import Bride from "./components/bride";
import BrideGroom from "./components/brideGroom";
import { GuestData } from "./components/cms/cmsadmin";
import CountDown from "./components/countDown";
import DateAndPlace from "./components/dateAndPlace";
import Gallery from "./components/gallery";
import animateScroll from "animated-scroll-to";
import Intro from "./components/intro";
import Video from "./components/video";
import { url } from "./constants";
import Surah from "./components/surah";
import Gift from "./components/gift";
import PoweredBy from "./components/poweredBy";
import Bgm from "./components/bgm";
import { Element } from "react-scroll";

export default function App() {
  const { uri } = useParams();
  const isNoUri = uri === undefined;
  const [guestData, setGuestData] = useState<GuestData>();
  const [playing, setPlaying] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isKahfiLoaded, setIsKahfiLoaded] = useState(false);
  const [isPutriLoaded, setIsPutriLoaded] = useState(false);
  const [isNotListed, setIsNotListed] = useState(false);
  const isImageLoaded = isKahfiLoaded && isPutriLoaded;

  useEffect(() => {
    const button = document.querySelector(".ScrollToTop");
    const handler = () => {
      animateScroll(0, { minDuration: 3000 });
    };
    button?.addEventListener("click", handler);

    return () => {
      button?.removeEventListener("click", handler);
    };
  });
  useEffect(() => {
    if (uri !== undefined) {
      getGuestByUri();
    } else {
      setGuestData({
        name: "Hadirin",
        partner: null,
        photo: "/icons/fallback.jpg",
        prefix: "",
      });
    }
    if (isNoUri || isNotListed) {
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGuestByUri = async () => {
    await axios
      .get(`${url}guests/${uri}`)
      .then((res) => {
        setIsFetching(false);
        setGuestData(res?.data?.data);
      })
      .catch((err) => {
        setIsFetching(false);
        setIsNotListed(true);
        setGuestData({
          name: "Hadirin",
          partner: null,
          photo: "/icons/fallback.jpg",
          prefix: "",
        });
      });
  };
  useEffect(() => {
    // Scroll to the top of the page every reload
    window.history.scrollRestoration = "manual";
    const videoElement: any = document.getElementById("video-player");

    videoElement?.addEventListener("loadeddata", (e: any) => {
      if (videoElement.readyState >= 3) {
        setIsVideoLoaded(true);
      }
    });
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);
  const onOpenInvitation = () => {
    document.body.style.overflowY = "scroll";
    const audio: any = document.getElementById("player") ?? "";
    const video: any = document.getElementById("video-player") ?? "";
    setPlaying(true);
    audio?.play();
    video?.play();
  };

  // Prevent infinite loading, then we make it forced set state
  useEffect(() => {
    setTimeout(() => {
      setIsKahfiLoaded(true);
      setIsPutriLoaded(true);
      setIsFetching(false);
      setIsVideoLoaded(true);
    }, 5000);
  }, []);

  return (
    <div className="bg-black relative">
      <Intro
        name={guestData?.name}
        partner={guestData?.partner}
        photo={guestData?.photo}
        prefix={guestData?.prefix}
        onOpenInvitation={onOpenInvitation}
        setIsKahfiLoaded={setIsKahfiLoaded}
        setIsPutriLoaded={setIsPutriLoaded}
      />
      <Element name="main">
        <Video />
        <CountDown isNotAuth={isNoUri || isNotListed} />
        <Surah />
        {isNoUri || isNotListed ? null : (
          <>
            <BrideGroom />
            <Bride />
            <DateAndPlace />
            <Gallery />
            <Gift />
            <Attendance
              guestId={guestData?.id}
              name={guestData?.name}
              prefix={guestData?.prefix}
              partner={guestData?.partner}
            />
          </>
        )}
        <PoweredBy />
      </Element>
      <Bgm playing={playing} setPlaying={setPlaying} />
      {isImageLoaded === false || isFetching || isVideoLoaded === false ? (
        <div className="absolute w-full h-screen bg-pink-primary top-0">
          <div className="relative w-full h-screen flex flex-col justify-center items-center">
            <svg
              className="spinner"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="50" cy="50" r="45" />
            </svg>
            <p className="mt-8 text-white text-[20px]">
              Menyiapkan undangan...
            </p>
          </div>
        </div>
      ) : null}
    </div>
    // ) : (
    //   <div className="h-screen flex items-center justify-center">
    //     <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    //       <circle cx="50" cy="50" r="45" />
    //     </svg>
    //   </div>
  );
}

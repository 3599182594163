import React from "react";

const Fade = require("react-reveal/Fade");

function Video() {
  return (
    <div className="mt-6 border rounded-lg border-grey-border p-6">
      <div className="mb-4">
        <div className="flex gap-2 items-center">
          <img src="/icons/bus.svg" alt="bus" />
          <span className="font-bold text-left">Menggunakan KRL</span>
        </div>
        <div className="w-full border-b border-grey-border mt-4" />
        {/* <img src="/images/dummyloc1.png" alt="loc1" className="my-6 w-full" /> */}
        <div className="relative w-full h-0 pb-[56.25%]">
          <iframe
            className="absolute top-0 left-0 w-[100%] h-[100%]"
            src="https://www.youtube.com/embed/f-nWITQq_8A?si=MQFMLFe8OzjyFJn5"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="mt-12">
        <div className="flex gap-2 items-center">
          <img src="/icons/bus.svg" alt="bus" />
          <span className="font-bold text-left">
            Kendaraan Pribadi Rute dari Bogor - Juanda - Margonda
          </span>
        </div>
        <div className="w-full border-b border-grey-border mt-4" />
        <div className="relative w-full h-0 pb-[56.25%]">
          <iframe
            className="absolute top-0 left-0 w-[100%] h-[100%]"
            src="https://www.youtube.com/embed/y1O3Qb0OnRw?si=YVFyBL8zTq8AnThA"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="mt-12">
        <div className="flex gap-2 items-center">
          <img src="/icons/bus.svg" alt="bus" />
          <span className="font-bold text-left">
            Kendaraan Pribadi Rute dari JORR - Tanjung Barat - Lenteng Agung
          </span>
        </div>
        <div className="w-full border-b border-grey-border mt-4" />
        <div className="relative w-full h-0 pb-[56.25%]">
          <iframe
            className="absolute top-0 left-0 w-[100%] h-[100%]"
            src="https://www.youtube.com/embed/WQVAV1IeY2E?si=EVCm7AsccU1jS4bd"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

function Gmaps() {
  return (
    <div className="mt-6 border rounded-lg border-grey-border p-6">
      <div className="mt-4">
        <div className="flex gap-2 items-center">
          <span className="font-bold">Peta lokasi</span>
        </div>
        <div className="w-full border-b border-grey-border my-4" />
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.3561757773505!2d106.82558597552992!3d-6.347905862106031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ec2fb60208d7%3A0xea90341c7682607e!2sGedung%20Sabha%20Widya!5e0!3m2!1sid!2sid!4v1700713802828!5m2!1sid!2sid"
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen={true}
          aria-hidden="false"
          tabIndex={0}
          title="map-wedding"
          loading="lazy"
          className="w-full mt-4"
        ></iframe>
      </div>
    </div>
  );
}

function LocationAndMap() {
  const [tab, setTab] = React.useState("video");
  const active =
    "bg-pink-secondary w-48 p-4 rounded-xl text-white font-bold focus:outline-none";
  const inActive =
    "bg-grey-secondary w-48 p-4 rounded-xl text-black font-bold focus:outline-none";

  return (
    <section className="container mx-auto max-w-7xl px-5 lg:px-0 relative">
      <div className="flex flex-col lg:flex-row items-center justify-between mx-auto w-full">
        <Fade bottom>
          <div className="w-full lg:mx-auto py-8 px-0 md:px-8 lg:py-16  bg-white">
            <div className="">
              <h2 className="text-black text-[40px] sn-font">
                Lokasi Pernikahan
              </h2>
            </div>
            <div className="flex gap-4 mt-12">
              <button
                onClick={() => setTab("video")}
                className={`${tab === "video" ? active : inActive}`}
              >
                Video Petunjuk Arah
              </button>
              <button
                onClick={() => setTab("gmaps")}
                className={`${tab !== "video" ? active : inActive}`}
              >
                Google Maps
              </button>
            </div>
            {tab === "video" ? <Video /> : <Gmaps />}
          </div>{" "}
        </Fade>
      </div>
    </section>
  );
}

export default LocationAndMap;

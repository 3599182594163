import { useEffect, useState } from "react";
import { Element } from "react-scroll";
import Intro from "../../components/intro";
import LocationAndMap from "../../components/location";
import PoweredBy from "../../components/poweredBy";

function Location() {
  const [isKahfiLoaded, setIsKahfiLoaded] = useState(false);
  const [isPutriLoaded, setIsPutriLoaded] = useState(false);
  const isImageLoaded = isKahfiLoaded && isPutriLoaded;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);
  const onOpenInvitation = () => {
    document.body.style.overflowY = "scroll";
  };

  return (
    <div>
      <Intro
        setIsKahfiLoaded={setIsKahfiLoaded}
        setIsPutriLoaded={setIsPutriLoaded}
        isLocationPage={true}
        onOpenInvitation={onOpenInvitation}
      />
      <Element name="main">
        <LocationAndMap />
        <PoweredBy />
      </Element>
      {isImageLoaded === false ? (
        <div className="absolute w-full h-screen bg-pink-primary top-0">
          <div className="relative w-full h-screen flex justify-center items-center">
            <svg
              className="spinner"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="50" cy="50" r="45" />
            </svg>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Location;
